import { useRef } from "react";
import { Link } from "react-router-dom";
// import "./Header.css";
import { Modal } from "./Modal";
import { useEffect, useState } from "react";
import { LocalStorage } from "../../core/Storage/LocalStorage";
import { AuthService } from "../../../apiServices/Services/AuthServices";
import { HandleAuthToken } from "../../core/Utils/HandleAuthToken";
import { useAppSelector } from "../../../app/hooks";
import {
  isLoggedIn,
  selectUser,
  lmsLink,
} from "../../../features/auth/AuthSlice";

import toast from "react-hot-toast";

import { toggleLoader } from "../../../apiServices/Services/CommonServices";

import TabChange from "../../../assets/icons/Asset8.png";
import TabChangewhite from "../../../assets/icons/Assetwhite.png";
import React from "react";

const objAuthService = new AuthService();
const objLocalStorage = new LocalStorage();
const objAuthHandler = new HandleAuthToken();
function Header(props: any) {
  const modalRef: any = useRef();
  const [isLoading, setisLoader] = useState(false);
  const lmsUrl = useAppSelector(lmsLink);

  const useScrollDirection = () => {
    const [scrollDirection, setScrollDirection] = React.useState("");

    React.useEffect(() => {
      let lastScrollY = window.pageYOffset;

      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        let direction = scrollY > lastScrollY ? "down" : "up";
        if (
          direction !== scrollDirection &&
          (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
        ) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener("scroll", updateScrollDirection); // add event listener
      return () => {
        window.removeEventListener("scroll", updateScrollDirection); // clean up
      };
    }, [scrollDirection]);
    return scrollDirection;
  };

  const scrollDirection = useScrollDirection();
  useEffect(() => {
    handleAutoLeadModal(45000);
  });

  const handleAutoLeadModal = (delay: number) => {
    setTimeout(() => {
      let token = objAuthHandler.getToken();
      let popup = objLocalStorage.getCookie("popup");
      if (!token && popup !== "true") {
        showAutoModal();
      }
    }, delay);
  };

  const showAutoModal = () => {};

  const profileData = () => {
    const data = {
      profileId: objAuthHandler.getProfile(),
    };

    // const headers = {};
    return objAuthService.lms(data);
  };

  const handleLms = () => {
    let lms_url = JSON.parse(objLocalStorage.getItem("lmsData") || "{}").lmsUrl;
    if (lms_url) {
      window.location.href = lms_url;
    } else {
      profileData().subscribe({
        next: (response) => {
          setisLoader(false);
          objLocalStorage.setItem("lmsData", JSON.stringify(response));
          window.location.href = response.lmsUrl;
        },
        error: (err) => {
          console.log(err.response.data);
          if (err.response.status === 500) {
            toast.error("Service unavailable.");
          } else {
            toast.error(err.response.data);
          }
        },
      });
    }
  };

  const isLoggedInUser = useAppSelector(isLoggedIn);
  let loggedInUser = useAppSelector(selectUser);

  const handleChangePassword = () => {
    modalRef.current.handleChangePassword();
  };

  return (
    <>
      {toggleLoader(isLoading)}
      <header
        className={`techxr-header-main ${
          scrollDirection === "down" ? "hideheader" : "showheader"
        }`}
      >
        <div className="top_stripe mx-w-1440">
          <div className="inner_stripe">
            {/* <p>
            Refer a friend for our Courses and earn Rs. 1000
            <a href="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/ZPZIlDqYEwFCCnh3_2022-10-03T080133760815.Referral-program.pdf">
              Learn More
            </a>
          </p> */}
            <p>
              SenseXR AR-VR Controller For Smartphone
              <a
                href="https://sensexr.techxr.co/products/sensexr-ar-vr-controller-for-smartphone"
                target="blank"
              >
                Buy Now
              </a>
              <div className="__counterblock">
                {/* <Countdown date={Date.now() + (finalTime - Date.now())}>
                <Completionist />
              </Countdown> */}
              </div>
            </p>
          </div>
        </div>
        <div className="header header mx-w-1440">
          <div className="logo-menu">
            <Link to="/">
              <div className="logo"></div>
            </Link>

            <label htmlFor="menucheck" className="menulabel">
              <span></span>
              <span></span>
              <span></span>
            </label>
            <input type="checkbox" id="menucheck" />

            <div className="nav-menu">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>

                <li className="dropdown ">
                  <button className="dropbtn dropdown-arrow">Products</button>
                  <div className="dropdown-content">
                    <Link to="/sensexr">SenseXR</Link>
                    <Link to="/xrblocks">XR Blocks</Link>
                    <Link to="https://durlabhdarshan.com" target="blank">Durlabh Darshan
                    <img src={TabChange} alt="" className="learn-icon" />
                      <img
                        src={TabChangewhite}
                        alt=""
                        className="learn-icon whiteimage"
                      />
                    </Link>
                    <Link to="/ourapps">Our Apps</Link>
                    <Link to="https://learn.techxr.co/learn" target="blank">
                      Learn
                      <img src={TabChange} alt="" className="learn-icon" />
                      <img
                        src={TabChangewhite}
                        alt=""
                        className="learn-icon whiteimage"
                      />
                    </Link>
                  </div>
                </li>

                {/* <li className="dropdown ">
                  <button className="dropbtn dropdown-arrow">Courses</button>
                  <div className="dropdown-content">
                    <Link to="/courses">Courses</Link> 
                     <Link to="/forum">Q&A</Link>
                    <a
                      href="https://learn.techxr.co/learn"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn
                      <img src={TabChange} alt="" className="learn-icon" />
                      <img
                        src={TabChangewhite}
                        alt=""
                        className="learn-icon whiteimage"
                      />
                    </a>
                  </div>
                </li>  */}
                {/* <li>
                  <a href="https://learn.techxr.co/learn" target="blank">
                  Learn
                      <img src={TabChange} alt="" className="learn-icon" />
                      
                  </a>
                </li> */}
                <li>
                  <a href="https://blogs.techxr.co" target="blank">
                    Blogs
                  </a>
                </li>

                <li className="dropdown ">
                  <button className="dropbtn dropdown-arrow">About Us</button>
                  <div className="dropdown-content">
                    <Link to="/about-us">About Us</Link>
                    <Link to="/events">Events</Link>
                  </div>
                </li>
                <li>
                  <Link to="/Opportunities">Career</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
              {isLoggedInUser ? (
                <div className="dropdown darkbtnmain">
                  <button className="dropbtn ">
                    {" "}
                    Hi {loggedInUser?.profile.firstName}
                  </button>
                  <div className="dropdown-content">
                    <Link
                      to=""
                      className="dropdown-item"
                      onClick={handleChangePassword}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Change Password
                    </Link>
                    <Link to="/myprojects">My Projects</Link>
                    <Link to="/logout">Logout</Link>
                  </div>
                </div>
              ) : (
                <a
                  href="#exampleModal"
                  data-bs-toggle="modal"
                  className="signup-btn"
                >
                  Login
                </a>
              )}
            </div>
          </div>
        </div>
      </header>

      <Modal afterLogin={props.afterLogin} ref={modalRef} />
    </>
  );
}

export default Header;
