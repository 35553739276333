import "./Footer.css";
import { Link } from "react-router-dom";
import Mobileimage from "../../../assets/images/MockUp3.png";
import GooglePlayStoreImage from "../../../assets/images/footer-top-image/google-playstore.png";
import AppleStoreImage from "../../../assets/images/footer-top-image/apple-store.png";
import FbTopImage from "../../../assets/images/footer-top-image/facebook.png";
import InstaTopImage from "../../../assets/images/footer-top-image/instagram.png";
import LinkedInTopImage from "../../../assets/images/footer-top-image/linkedin.png";
import LogoImage from "../../../assets/images/logo.png";
import FbImage from "../../../assets/images/footer/facebook.png";
import InstaImage from "../../../assets/images/footer/instagram.png";
import LinkedInImage from "../../../assets/images/footer/linkedin.png";
import { useAppSelector } from "../../../app/hooks";
import { lmsLink } from "../../../features/auth/AuthSlice";

function Footer() {
  const lmsUrl = useAppSelector(lmsLink);
  // console.log(lmsUrl);

  // console.log(isLoggedInUser);

  return (
    <>
      <div className="footer-top mx-w-1440">
        <div className="footer-top-inner">
          <div className="common-container">
            <div className="common-row align-items ">
              <div className="common-col-6 common-xs-12">
                <div className="footer-top-block-left">
                  <div className="footer-top-block-img">
                    <img src={Mobileimage} alt="Mobile1Image" />
                  </div>
                </div>
              </div>

              <div className="common-col-5 common-xs-12">
                <div className="footer-top-block-right">
                  <div className="footer-top-block-right-text">
                    <h2>Download Our App Now!</h2>
                    <p>Get our mobile app and learn about XR the right way</p>
                  </div>
                  <div className="footer-top-block-right-btn d-flex">
                    <div className="play-store-btn">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.techxrlearn.courses"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={GooglePlayStoreImage}
                          alt="GooglePlayStoreImage"
                        />
                      </a>
                    </div>
                    <div className="apple-store-btn">
                      <a
                        href="https://apps.apple.com/in/app/techxr-learn/id1606126751"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={AppleStoreImage} alt="AppleStoreImage" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer py-60 mx-w-1440">
        {/* <!------- Footer Block Middle Start ------------------> */}
        <div className="footer-block common-container">
          <div className="common-row">
            <div className="footer-block-left common-col-4 common-md-12 common-xs-12">
              <div className="footer-logo">
                <Link to="/">
                  <img src={LogoImage} alt="TechXRLogo" />
                </Link>
              </div>
            </div>

            <div className="footer-block-right common-col-8 common-md-12">
              <div className="common-row">
                <div className="footer-block-right-section common-col-3 common-xs-12">
                  <div className="footer-block-right-col-heading">
                    <h5> TechXR </h5>
                    <ul>
                      {/* <li>
                        <Link to="/courses">Courses</Link>
                      </li> */}
                      <li>
                        <Link to="https://durlabhdarshan.com">
                          Durlabh Darshan
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="/projects">Projects</Link>
                      </li> */}
                      {/* <li>
                        <a href={lmsUrl}>Learning Portal</a>
                      </li> */}
                      <li>
                        <Link to="/sensexr">SenseXR Controller</Link>
                      </li>
                      <li>
                        <Link to="/xrblocks">XR Blocks </Link>
                      </li>
                      {/* <li>
                    <a href="/projects">Projects</a>
                  </li> */}
                      {/* <li>
                    <a href="#">Developers</a>
                  </li> */}
                    </ul>
                  </div>
                </div>

                <div className="footer-block-right-section common-col-3 common-xs-12">
                  {/* <!--footer-block Right col Heading Start--> */}
                  <div className="footer-block-right-col-heading">
                    <h5> About US </h5>

                    <ul>
                      <li>
                        <Link to="/about-us">About Us</Link>
                      </li>
                      <li>
                        <Link to="/events">Events</Link>
                      </li>
                      {/* <li>
                    <a href="/faqs">Faqs</a>
                  </li> */}

                      <li>
                        <a href="https://blogs.techxr.co">Blogs</a>
                      </li>
                      {/* <li>
                        <Link to="/sdkdownload">SDK Download</Link>
                      </li> */}
                      {/* <li>
                        <a
                          href="https://techxrinnovationpvtltd.zohodesk.in/portal/en/home"
                          className="highlight"
                        >
                          Raise a ticket
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </div>

                <div className="footer-block-right-section common-col-3 common-xs-12">
                  <div className="footer-block-right-col-heading">
                    <h5> Apps </h5>

                    <ul>
                      <li>
                        <a href="https://play.google.com/store/apps/details?id=com.TechXR.SenseXRExperiences&hl=en-IN">
                        Sensexr App 
                        </a>
                      </li>
                      
                      <li>
                        <a href="https://play.google.com/store/apps/details?id=com.TechXR.DurlabhDarshan&hl=en-IN">
                        Durlabh Darshan App
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.TechXRInnovations.TechXRDevCubeDemo"
                          target="_blank"
                          rel="noreferrer"
                        >
                          CubeTour (Android)
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://apps.apple.com/in/app/techxr-cube-tour/id1629014478"
                          target="_blank"
                          rel="noreferrer"
                        >
                          CubeTour (iOS)
                        </a>
                      </li>
                      <li>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.TechXR.Holocube"
                      target="_blank"
                      rel="noreferrer"
                    >
                     Holocube
                    </a>
                  </li>
                      <li>
                        <Link to="/ourapps">All Apps</Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="footer-block-right-section common-col-3 common-xs-12">
                  <div className="footer-block-right-col-heading">
                    <h5> Terms</h5>

                    <ul>
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/refund-policy">Refund Policy</Link>
                      </li>
                      <li>
                        <Link to="/terms">Terms Of Use</Link>
                      </li>
                    </ul>
                    <div className="social">
                      <ul>
                        <li>
                          <a
                            href="https://www.facebook.com/TechXRinnovations/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={FbImage} alt="FBIcon" />
                          </a>
                        </li>
                        <li>
                          {" "}
                          <a
                            href="https://www.instagram.com/techxrinnovations"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={InstaImage} alt="InstaIcon" />
                          </a>
                        </li>
                        <li>
                          {" "}
                          <a
                            href="https://www.linkedin.com/company/techxr/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={LinkedInImage} alt="LinkedInIcon" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* <!--footer-block Right col Heading End--> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!------- Footer Block Middle End ------------------> */}

        {/* <!------- Footer Block Bottom Start ------------------> */}
        <div className="footer-block-bottom pt-50">
          <div className="common-container">
            <div className="common-row">
              <div className="footer-block-left-text-bottom common-col-3 common-md-12">
                TechXR Innovations Pvt. Ltd.
                <br />
                <span> CIN U72900MP2021PTC054414</span>
              </div>

              <div className="footer-block-bottom-right-section common-col-2 common-md-4 common-xs-12">
                <div className="footer-block-right-col-bottom-heading">
                  <h5>Connect </h5>

                  <div className="social">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/TechXRinnovations/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={FbTopImage} alt="FbTopImage" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/techxrinnovations"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={InstaTopImage} alt="InstaTopImage" />
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a
                          href="https://www.linkedin.com/company/techxr/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={LinkedInTopImage} alt="LinkedInTopImage" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="footer-block-bottom-right-section common-col-3 common-md-4 common-xs-12">
                <div className="footer-block-right-col-bottom-heading">
                  <h5> Address</h5>

                  <p>SF-15 Second Floor Aakriti Business Centre,</p>
                  <p>Aakriti Eco City, Bhopal </p>
                  <p> India - 462026</p>
                </div>
              </div>

              <div className="footer-block-bottom-right-section common-col-4 common-md-4 common-xs-12">
                <div className="footer-block-right-col-bottom-heading">
                  <h5> Contact</h5>

                  <p>sensexr@techxr.co</p>

                  <p>
                    For Any <br />
                    Queries -<span>9109131271 </span>
                  </p>

                  {/* <p>
                    For AR-VR Course <br /> related queries -
                    <span>+91 9109131271 </span>
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-----------------------Footer Block Bootom End -----------------> */}
    </>
  );
}

export default Footer;
