import * as React from "react";
import Conbg from "../../../assets/images/rect.png";
import Controler from "../../../assets/images/About-Us.png";
import Vectorbg from "../../../assets/images/Vectorbg.png";
import Visionmain from "../../../assets/images/engineer-controls-robotic-arms-by.jpg";
import Visionbg from "../../../assets/images/auth-pages-background/bg_png.png";
import { PageBackgroundLeft } from "../CommonComponents/PageBackgroundLeft";
import { PageBackgroundRight } from "../CommonComponents/PageBackgroundRight";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";
import myimg from "../../../assets/images/controller + oculus.png";
import Tabs, { Tab } from "react-best-tabs";
import "react-best-tabs/dist/index.css";

interface AboutUsProps {}

const AboutUsSecond: React.FunctionComponent<AboutUsProps> = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>About us | TechXR</title>
          <meta name="title" content="About us | TechXR" />
          <meta
            name="description"
            content="Our mission to democratize the access to emerging technologies, like Augmented, Virtual and Mixed Reality. Read our about us section. Visit TechXR to know more!"
          />
          <meta property="image" content={Controler} />
        </Helmet>
      </HelmetProvider>{" "}
      <>
        <div className="abputus-main-block mx-w-1440">
          <PageBackgroundLeft />
          <PageBackgroundRight />
          <div className="common-container">
            <div className="common-heading">
              <h1>About Us</h1>
            </div>

            <div className="main-app-tabs mb-100">
              <Tabs
                className=""
                ulClassName=""
                activityClassName="bg-success"
                onClick={(event, tab) => console.log(event, tab)}
              >
                <Tab title="English" className="mr-3">
                  <div className="about_section_new mb-100 ">
                    <div className="common_about_heading mb-50">
                      <h2>
                        <span> About</span>
                        TechXR Innovations
                      </h2>
                      <p>
                        We Are An Award Winning Start Up Based Out Of Bhopal And
                        Gurugram
                      </p>
                    </div>
                    <div className="about_main_content_new">
                      <div className="bullot_point_section">
                        <ul>
                          <li>
                            <img
                              src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/JKA7tUTOFXZdp5TE_2023-04-03T103341220828.startup.png"
                              alt=""
                            />
                            <p>
                              A Fast growing startup Floated by team of IITians
                              on 7th January 2021
                            </p>
                          </li>

                          <li>
                            <img
                              src=" https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/L5Xg8JoUw3sHwsXp_2023-04-03T113021197401.DoT (2).png"
                              alt=""
                            />
                            <p>
                              We have been awarded Top 12 Innovations by DOT
                              Govt. of India
                            </p>
                          </li>
                          <li>
                            <img
                              src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/UEpxppJiFuYfVr4G_2023-04-03T103200853650.cm2OK61LI5ZusyzY_2022-11-17T131058749173.Yourstory (1).png"
                              alt=""
                            />
                            <p>
                              Top 30 Most Promising Startup in TechSparks by
                              YourStory for Year 2022
                            </p>
                          </li>

                          <li>
                            <img
                              src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/jfoRbcgW7ejr5oJJ_2023-04-03T103447239983.Patented.png "
                              alt=""
                            />
                            <p>
                              Patented Product in Augmented & Virtual Reality
                            </p>
                          </li>
                          <li>
                            <img
                              src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/02FIZAxLIFdWXPbE_2023-04-03T103506470642.newspaper.png "
                              alt=""
                            />
                            <p>Featured in 20+ News and media articles</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="about_section_new mb-100 ">
                    <div className="common_about_heading">
                      <h2>
                        <span> Our</span>
                        Products
                      </h2>
                    </div>

                    <div className="common_aboutsub_heading">
                      <h2>
                        {/* <span> Our</span> */}
                        SenseXR 6DoF Controller
                      </h2>
                      <p>AR-VR Controller for smartphones</p>
                    </div>

                    <div className="Video_and_audio_container">
                      <div className="common-row">
                        <div className="common-col-7 common-md-12">
                          <div className="ifarme_for_video">
                            <iframe
                              width="942"
                              height="530"
                              src="https://www.youtube.com/embed/NbR1EOV3nYs"
                              title="SenseXR 3-in-1 AR-VR Controller for Immersive Education on Smartphone"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                            ></iframe>
                          </div>
                        </div>
                        <div className="common-col-5 common-md-12">
                          <div className="super_sub_head">
                            <h2>Our Apps </h2>
                          </div>

                          <div className="video_audio_app_list">
                            <ul>
                              <li>
                                <div className="apps_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/sLuI9hN6ExU3kD33_2023-04-03T045824556316.Adaptation.png"
                                    alt=""
                                  />
                                  <span>Adaptation</span>
                                </div>
                              </li>

                              <li>
                                <div className="apps_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/sgnDMgMKPJZked7B_2023-04-03T121058195459.Nutrition in Animals.png"
                                    alt=""
                                  />
                                  <span>Nutrition in Animals</span>
                                </div>
                              </li>

                              <li>
                                <div className="apps_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/EvKPBInCn1t5j4vQ_2023-04-03T120923773860.reptile.png"
                                    alt=""
                                  />
                                  <span>Reptile</span>
                                </div>
                              </li>

                              <li>
                                <div className="apps_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/RfqMvCS2OsutZyqu_2023-04-03T050141583474.bird.png"
                                    alt=""
                                  />
                                  <span>bird sanctuary</span>
                                </div>
                              </li>

                              <li>
                                <div className="apps_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/AgGBzJuCF8bctYaR_2023-04-03T121234560433.Life Processes.png"
                                    alt=""
                                  />
                                  <span>Life Processes</span>
                                </div>
                              </li>
                              <li>
                                <div className="apps_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/4E83YGItYROkQY15_2023-04-03T050304113918.butterfly.png"
                                    alt=""
                                  />
                                  <span>butterfly</span>
                                </div>
                              </li>

                              <li>
                                <div className="apps_base">
                                  <img
                                    src=" https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/BHR92NmoXrS1X4Te_2023-04-03T050332526080.ChickenLifeCycle.jpg"
                                    alt=""
                                  />
                                  <span>Chicken Life Cycle</span>
                                </div>
                              </li>
                              <li>
                                <div className="apps_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/lMP7hdrZRc18UvEA_2023-04-03T120528663039.geology.jpg"
                                    alt=""
                                  />
                                  <span>geology</span>
                                </div>
                              </li>
                              <li>
                                <div className="apps_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/WDnoxFhAcrEFaEaZ_2023-04-03T121345492706.Major Landforms of the Earth.png"
                                    alt=""
                                  />
                                  <span>Major Landforms</span>
                                </div>
                              </li>
                              <li>
                                <div className="apps_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/9glUvFieuW3zqIcb_2023-04-03T050508604972.constellations.png"
                                    alt=""
                                  />
                                  <span>constellations</span>
                                </div>
                              </li>
                              <li>
                                <div className="apps_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/qIGkJSpcCyEgenSP_2023-04-03T051615052947.dino.png"
                                    alt=""
                                  />
                                  <span>dino world</span>
                                </div>
                              </li>
                              <li>
                                <div className="apps_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/pMrozSSoRvMkdRVf_2023-04-03T051652834890.frog.png"
                                    alt=""
                                  />
                                  <span>frog Life Cycle</span>
                                </div>
                              </li>
                            </ul>

                            <div className="see_all">
                              <Link to="/ourapps">See all</Link>
                            </div>
                          </div>
                        </div>
                        <div className="common-col-12">
                          <div className="shordetails_for_video">
                            <p>
                              An Advanced Smartphone Based AR-VR Learning Tool
                              for Ages 5+. TechXR has launched it’s own XR
                              Controller which allows 6DoF Movement in the
                              Augmented and Virtual Reality Environment. Connect
                              this controller with your Smartphone and
                              experience your AR-VR world with interaction like
                              never before. The distinguishing features of
                              SenseXR are :
                            </p>
                            <ul>
                              <li>
                                It is compatible with popular smartphones.
                              </li>
                              <li>
                                It provides 6DoF interaction in the AR & VR
                                space.
                              </li>
                            </ul>
                            <p>
                              SenseXR Controller can be used as a go-to device
                              for diving into VR or can also be used to start
                              your journey as an AR/VR Creator. With SenseXR
                              Controller you can either create your own Science
                              Lab or a Shooting Game or Nano Metaverse of your
                              own imagination. The possibilities are countless.
                            </p>
                          </div>
                        </div>

                        <div className="common-col-12">
                          <div className="mini_flex_box">
                            <div className="sPrice_for_video">
                              <p>
                                <del>₹ 5999/-</del> <strong> ₹ 3999/-</strong>
                              </p>
                              <small>Limited time offer</small>
                              <a
                                href="https://www.amazon.in/dp/B0BNKRMVL7"
                                className="buyonAmazon"
                                target="blank"
                              >
                                <img
                                  src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/gEG7vtucG9oHOkeJ_2023-04-01T093758138342.buy-on-amazon.png"
                                  alt=""
                                />
                              </a>
                            </div>
                            <div className="sPrice_for_video">
                              <p>
                                <del>₹ 5999/-</del> <strong> ₹ 2999/-</strong>
                              </p>
                              <small>Limited time offer</small>
                              <a
                                href="https://sensexr.techxr.co/products/sensexr-ar-vr-controller-for-smartphone"
                                className="buyonAmazon"
                                target="blank"
                              >
                                <img
                                  src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/91MlmiGekVGPke4r_2023-09-06T090807349066.TechXR Store Logo.png"
                                  alt=""
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="about_section_new mb-100 ">
                    <div className="common_aboutsub_heading">
                      <h2>XR Blocks</h2>
                      <p>AR-VR Creation Plateform By TechXR</p>
                    </div>
                    <div className="Video_and_audio_container">
                      <div className="common-row">
                        <div className="common-col-7 common-md-12">
                          <div className="ifarme_for_video">
                            <img
                              src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/JsEqUXzp3LiUwtZR_2023-04-03T062251737108.Website XR blcks (1).jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="common-col-5 common-md-12">
                          <div className="super_sub_head">
                            <h2>
                              Create Your Own AR VR Content in{" "}
                              <span>XR Blocks</span> with senseXR controller{" "}
                            </h2>
                          </div>
                          <div className="card_va_list">
                            <ul>
                              <li>
                                <div className="card_va_inner">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/7buIJVibjWsiDOjj_2023-04-01T115617786945.Icon 2.png"
                                    alt=""
                                  />
                                  <strong>block based coding</strong>
                                </div>
                              </li>

                              <li>
                                <div className="card_va_inner">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/x9arT90kEqsmYSWR_2023-04-01T115840333942.Icon 5.png"
                                    alt=""
                                  />
                                  <strong>Drag and drop based</strong>
                                </div>
                              </li>

                              <li>
                                <div className="card_va_inner">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/NhZq1f1Xd8fG0cDS_2023-04-01T115908169988.Icon 6.png"
                                    alt=""
                                  />
                                  <strong>STEM learning</strong>
                                </div>
                              </li>

                              <li>
                                <div className="card_va_inner">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/KOaE61X6jojA9q1l_2023-04-01T115940462242.Icon1.png"
                                    alt=""
                                  />
                                  <strong>anaglyph mode</strong>
                                </div>
                              </li>

                              <li>
                                <div className="card_va_inner">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/hREVvd0bOf1ectoZ_2023-04-01T120010204282.Icon 3.png"
                                    alt=""
                                  />
                                  <strong>1500+ 3D Models assets</strong>
                                </div>
                              </li>

                              <li>
                                <div className="card_va_inner">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/Sd5OXkWGZQLWl0kc_2023-04-01T120029486012.Icon 4.png"
                                    alt=""
                                  />
                                  <strong>logic based</strong>
                                </div>
                              </li>
                            </ul>

                            <div className="see_all">
                              <Link to="/xrbloks">Download</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="about_section_new mb-100 video__new_iframe">
                    <div className="common_aboutsub_heading">
                      <h2>
                      Professional AR-VR Application Development Courses
                      </h2>
                      <p>
                        We provide AR-VR App Development Training with the
                        world’s most affordable Smartphone Based Developer Kit.
                      </p>
                    </div>

                    <div className="Video_and_audio_container">
                      <div className="common-row">
                        <div className="common-col-7 m-auto common-md-12">
                          <div className="ifarme_for_video">
                            <iframe
                              width="942"
                              height="530"
                              src="https://www.youtube.com/embed/bUdPvNyGgFg"
                              title="Advance AR-VR Development Course Learnings"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                            ></iframe>
                          </div>
                        </div>
                        <div className="common-col-12 common-md-12 mt-50">
                          <div className="super_sub_head text-center mt-40">
                            <h2>Our AR-VR Development courses </h2>
                          </div>

                          <div className="video_audio_Course_list">
                            <ul className="common-row">
                              <li className="common-col-3 common-sm-6 ">
                                <div className="course_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/HbI4rxI4JkFTyltG_2023-04-07T093434897836.Thumbnail-intermediate.jpg"
                                    alt=""
                                  />

                                  <div className="coursepoint">
                                    <ul>
                                      <li>
                                        <span>Course Duration :</span>{" "}
                                        <strong>8 weeks</strong>
                                      </li>
                                      <li>
                                        <span>
                                          Kit included : <br />{" "}
                                          <span className="include_span">
                                            senseXR Kit
                                          </span>
                                        </span>{" "}
                                        <strong>
                                          <img
                                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/pIgxe7eqw8eKTnYl_2023-04-04T102954450135.Kit.png"
                                            alt=""
                                          />
                                        </strong>
                                      </li>
                                    </ul>

                                    <a
                                      href="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/396WSaxZNKz0bKEc_2023-04-01T123952180798.Intermediate Brochure.pdf"
                                      className="downloadBtn"
                                    >
                                      Download brochure
                                    </a>
                                  </div>
                                </div>
                              </li>

                              <li className="common-col-3 common-sm-6">
                                <div className="course_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/4Q9436wGMOCPKj5b_2023-04-07T093542334335.Thumbnail-Advance.jpg"
                                    alt=""
                                  />

                                  <div className="coursepoint">
                                    <ul>
                                      <li>
                                        <span>Course Duration :</span>{" "}
                                        <strong>12 weeks</strong>
                                      </li>
                                      <li>
                                        <span>
                                          Kit included :{" "}
                                          <span className="include_span">
                                            senseXR Kit
                                          </span>
                                        </span>{" "}
                                        <strong>
                                          <img
                                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/pIgxe7eqw8eKTnYl_2023-04-04T102954450135.Kit.png"
                                            alt=""
                                          />
                                        </strong>
                                      </li>
                                    </ul>
                                    <a
                                      href="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/CAdQP5s6ZfgCCkHF_2023-04-01T124203026308.Advance.pdf"
                                      className="downloadBtn"
                                    >
                                      Download brochure
                                    </a>
                                  </div>
                                </div>
                              </li>

                              <li className="common-col-3 common-sm-6">
                                <div className="course_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/Dt2N3fSH2iF8wYOc_2023-04-03T115310785038.Thumbnail-Expert.jpg"
                                    alt=""
                                  />

                                  <div className="coursepoint">
                                    <ul>
                                      <li>
                                        <span>Course Duration :</span>{" "}
                                        <strong>16 weeks</strong>
                                      </li>
                                      <li>
                                        <span>
                                          Kit included :{" "}
                                          <span className="include_span">
                                            senseXR Kit
                                          </span>
                                        </span>{" "}
                                        <strong>
                                          <img
                                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/pIgxe7eqw8eKTnYl_2023-04-04T102954450135.Kit.png"
                                            alt=""
                                          />
                                        </strong>
                                      </li>
                                    </ul>
                                    <a
                                      href="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/grQMmvnLe4Oi0Rpr_2023-04-07T075855833230.Expert-editable.pdf"
                                      className="downloadBtn"
                                    >
                                      Download brochure
                                    </a>
                                  </div>
                                </div>
                              </li>

                              <li className="common-col-3 common-sm-6">
                                <div className="course_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/4Pr8R03f5rhlBVui_2023-04-03T115517643386.Thumbnail-Professional.jpg"
                                    alt=""
                                  />

                                  <div className="coursepoint">
                                    <ul>
                                      <li>
                                        <span>Course Duration :</span>{" "}
                                        <strong>24 weeks</strong>
                                      </li>
                                      <li>
                                        <span>
                                          Kit included :{" "}
                                          <span className="include_span">
                                            SenseXR Kit + Meta Quest 2
                                          </span>
                                        </span>{" "}
                                        <strong>
                                          <div className="flex-box_n">
                                            <img src={myimg} alt="" />
                                          </div>
                                        </strong>
                                      </li>
                                    </ul>

                                    <a
                                      href="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/sMXBcY7YML8uZnfg_2023-08-07T112955144811.TechXR Innovations Course.pdf"
                                      className="downloadBtn"
                                    >
                                      Download brochure
                                    </a>
                                  </div>
                                </div>
                              </li>
                            </ul>

                            <div className="see_all mt-40">
                              <Link to="/courses">See all</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab title="Hindi" className="mr-3">
                  <div className="about_section_new mb-100 ">
                    <div className="common_about_heading mb-50">
                      <h2>
                        <span> TechXR Innovations</span>
                        के बारे में जाने
                      </h2>
                      <p>
                        TechXR एक भोपाल और गुरुग्राम में स्थापित, सरकार द्वारा
                        पुरस्कार विजेता स्टार्टअप है
                      </p>
                    </div>
                    <div className="about_main_content_new">
                      <div className="bullot_point_section">
                        <ul>
                          <li>
                            <img
                              src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/JKA7tUTOFXZdp5TE_2023-04-03T103341220828.startup.png"
                              alt=""
                            />
                            <p>
                              यह सफल स्टार्टअप IITians द्वारा 07 जनवरी 2021 को
                              शुरू किया गया था
                            </p>
                          </li>

                          <li>
                            <img
                              src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/L5Xg8JoUw3sHwsXp_2023-04-03T113021197401.DoT (2).png"
                              alt=""
                            />
                            <p>
                              भारत संचार मंत्रालय द्वारा हमारी आधुनिक तकनीक को
                              प्रथम 12 में सम्मानित किया गया
                            </p>
                          </li>
                          <li>
                            <img
                              src=" https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/UEpxppJiFuYfVr4G_2023-04-03T103200853650.cm2OK61LI5ZusyzY_2022-11-17T131058749173.Yourstory (1).png"
                              alt=""
                            />
                            <p>
                              TechSparks के YourStory साल 2022 द्वारा 30 सबसे
                              आधुनिक स्टार्टअप में शामिल होने पर सम्मानित किया
                              गया
                            </p>
                          </li>

                          <li>
                            <img
                              src=" https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/jfoRbcgW7ejr5oJJ_2023-04-03T103447239983.Patented.png "
                              alt=""
                            />
                            <p>
                              वर्चुअल और ऑगमेंटेड रियलिटी की श्रेणी में पेटेंट
                              प्राप्त हुआ
                            </p>
                          </li>
                          <li>
                            <img
                              src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/02FIZAxLIFdWXPbE_2023-04-03T103506470642.newspaper.png"
                              alt=""
                            />
                            <p>
                              20 + समाचार और मीडिया के लेखों में विशेष स्थान
                              प्राप्त हुआ
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="about_section_new mb-100 ">
                    <div className="common_about_heading">
                      <h2>
                        हमारे
                        <span> प्रोडक्ट्स</span>
                      </h2>
                    </div>

                    <div className="common_aboutsub_heading">
                      <h2>
                        {/* <span> Our</span> */}
                        SenseXR 6DoF controller
                      </h2>
                      <p>स्मार्टफोन के लिए AR-VR controller</p>
                    </div>

                    <div className="Video_and_audio_container">
                      <div className="common-row">
                        <div className="common-col-7 common-md-12">
                          <div className="ifarme_for_video">
                            <iframe
                              width="942"
                              height="530"
                              src="https://www.youtube.com/embed/ua7Xh1cYCeU"
                              title="introducing SenseXR in Hindi"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                            ></iframe>
                          </div>
                        </div>

                        <div className="common-col-5 common-md-12">
                          <div className="super_sub_head">
                            <h2>
                              मुफ्त{" "}
                              <span>
                                30+ एप्प्स और 200+ AR-VR संबंधित अनुभव
                              </span>
                            </h2>
                          </div>

                          <div className="video_audio_app_list">
                            <ul>
                              <li>
                                <div className="apps_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/sLuI9hN6ExU3kD33_2023-04-03T045824556316.Adaptation.png"
                                    alt=""
                                  />
                                  <span>Adaptation</span>
                                </div>
                              </li>

                              <li>
                                <div className="apps_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/sgnDMgMKPJZked7B_2023-04-03T121058195459.Nutrition in Animals.png"
                                    alt=""
                                  />
                                  <span>Nutrition in Animals</span>
                                </div>
                              </li>

                              <li>
                                <div className="apps_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/EvKPBInCn1t5j4vQ_2023-04-03T120923773860.reptile.png"
                                    alt=""
                                  />
                                  <span>Reptile</span>
                                </div>
                              </li>

                              <li>
                                <div className="apps_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/RfqMvCS2OsutZyqu_2023-04-03T050141583474.bird.png"
                                    alt=""
                                  />
                                  <span>bird sanctuary</span>
                                </div>
                              </li>

                              <li>
                                <div className="apps_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/AgGBzJuCF8bctYaR_2023-04-03T121234560433.Life Processes.png"
                                    alt=""
                                  />
                                  <span>Life Processes</span>
                                </div>
                              </li>
                              <li>
                                <div className="apps_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/4E83YGItYROkQY15_2023-04-03T050304113918.butterfly.png"
                                    alt=""
                                  />
                                  <span>butterfly</span>
                                </div>
                              </li>

                              <li>
                                <div className="apps_base">
                                  <img
                                    src=" https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/BHR92NmoXrS1X4Te_2023-04-03T050332526080.ChickenLifeCycle.jpg"
                                    alt=""
                                  />
                                  <span>Chicken Life Cycle</span>
                                </div>
                              </li>
                              <li>
                                <div className="apps_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/lMP7hdrZRc18UvEA_2023-04-03T120528663039.geology.jpg"
                                    alt=""
                                  />
                                  <span>geology</span>
                                </div>
                              </li>
                              <li>
                                <div className="apps_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/WDnoxFhAcrEFaEaZ_2023-04-03T121345492706.Major Landforms of the Earth.png"
                                    alt=""
                                  />
                                  <span>Major Landforms</span>
                                </div>
                              </li>
                              <li>
                                <div className="apps_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/9glUvFieuW3zqIcb_2023-04-03T050508604972.constellations.png"
                                    alt=""
                                  />
                                  <span>constellations</span>
                                </div>
                              </li>
                              <li>
                                <div className="apps_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/qIGkJSpcCyEgenSP_2023-04-03T051615052947.dino.png"
                                    alt=""
                                  />
                                  <span>dino world</span>
                                </div>
                              </li>
                              <li>
                                <div className="apps_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/pMrozSSoRvMkdRVf_2023-04-03T051652834890.frog.png"
                                    alt=""
                                  />
                                  <span>frog Life Cycle</span>
                                </div>
                              </li>
                            </ul>

                            <div className="see_all">
                              <Link to="/ourapps">See all</Link>
                            </div>
                          </div>
                        </div>

                        <div className="common-col-12">
                          <div className="shordetails_for_video">
                            <p>
                              5 + आयु के बच्चो क लिए, स्मार्टफोन संबंधित शिक्षा
                              की श्रेणी में बनाया गया सर्वोच्च AR -VR टूल ।
                              TechXR द्वारा लॉन्च किया गया खुद का XR Controller
                              जो की उपयोग करने वाले को वर्चुअल और ऑगमेंटेड
                              रियलिटी में 6DoF Interaction की अनुमति देता है ।
                              अपने स्मार्टफोन को SenseXR Controller से ब्लूटूथ
                              द्वारा जोड़े और वर्चुअल तथा ऑगमेंटेड रियलिटी का
                              अनुभव कर। SenseXR Contoroller के फीचर्स क बारे में
                              जाने -
                            </p>
                            <ul>
                              <li>
                                यह सभी लोकप्रिय स्मार्टफोन के साथ अनुकूल है
                              </li>
                              <li>
                                यह AR -VR संबंधित 6DoF Interaction प्रदान करता
                                है
                              </li>
                            </ul>
                            <p>
                              SenseXR Contoroller वर्चुअल रियलिटी का अनुभव
                              प्रदान करता है साथ ही यह एक वर्चुअल रियलिटी जगह
                              बनाने क योग्य भी ह। इसके द्वारा आप अपनी AR -VR
                              Creator के सफर की शुरुआत भी कर सकते है। SenseXR
                              Contoroller के साथ आप अपना खुद का AR -VR पे आधारित
                              विज्ञान प्रयोगशाला, शूटिंग गेम्स, मिनी मेटावर्स,
                              अन्य अनुभव बना सकते हैं।
                            </p>
                          </div>
                        </div>

                        <div className="common-col-12">
                          <div className="mini_flex_box">
                            <div className="sPrice_for_video">
                              <p>
                                <del>₹ 5999/-</del> <strong> ₹ 3999/-</strong>
                              </p>
                              <small>सीमित समय ऑफर</small>
                              <a
                                href="https://www.amazon.in/dp/B0BNKRMVL7"
                                className="buyonAmazon"
                              >
                                <img
                                  src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/gEG7vtucG9oHOkeJ_2023-04-01T093758138342.buy-on-amazon.png"
                                  alt=""
                                />
                              </a>
                            </div>
                            
                            <div className="sPrice_for_video">
                              <p>
                                <del>₹ 5999/-</del> <strong> ₹ 2999/-</strong>
                              </p>
                              <small>सीमित समय ऑफर</small>
                              <a
                                href="https://www.amazon.in/dp/B0BNKRMVL7"
                                className="buyonAmazon"
                              >
                                <img
                                  src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/91MlmiGekVGPke4r_2023-09-06T090807349066.TechXR Store Logo.png"
                                  alt=""
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="about_section_new mb-100 ">
                    <div className="common_aboutsub_heading">
                      <h2>
                        {/* <span> Our</span> */}
                        XR Blocks
                      </h2>
                      <p>टेकएक्सआर द्वारा एआर-वीआर क्रिएशन प्लेटफॉर्म</p>
                    </div>
                    <div className="Video_and_audio_container">
                      <div className="common-row">
                        <div className="common-col-7 common-md-12">
                          <div className="ifarme_for_video">
                            <img
                              src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/JsEqUXzp3LiUwtZR_2023-04-03T062251737108.Website XR blcks (1).jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="common-col-5 common-md-12">
                          <div className="super_sub_head">
                            <h2>
                              बनाये अपने खुद के AR -VR अनुभव{" "}
                              <span>XR Blocks </span> में SenseXR Contoroller की
                              मदद से
                            </h2>
                          </div>
                          <div className="card_va_list">
                            <ul>
                              <li>
                                <div className="card_va_inner">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/7buIJVibjWsiDOjj_2023-04-01T115617786945.Icon 2.png"
                                    alt=""
                                  />
                                  <strong> ब्लॉक पर आधारित कोडिंग</strong>
                                </div>
                              </li>

                              <li>
                                <div className="card_va_inner">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/x9arT90kEqsmYSWR_2023-04-01T115840333942.Icon 5.png"
                                    alt=""
                                  />
                                  <strong> ड्रैग एंड ड्राप आधारित </strong>
                                </div>
                              </li>

                              <li>
                                <div className="card_va_inner">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/NhZq1f1Xd8fG0cDS_2023-04-01T115908169988.Icon 6.png"
                                    alt=""
                                  />
                                  <strong>STEM आधारित अनुभव</strong>
                                </div>
                              </li>

                              <li>
                                <div className="card_va_inner">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/KOaE61X6jojA9q1l_2023-04-01T115940462242.Icon1.png"
                                    alt=""
                                  />
                                  <strong>3D मोड </strong>
                                </div>
                              </li>

                              <li>
                                <div className="card_va_inner">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/hREVvd0bOf1ectoZ_2023-04-01T120010204282.Icon 3.png"
                                    alt=""
                                  />
                                  <strong>1500 + 3D मॉडल सहित </strong>
                                </div>
                              </li>

                              <li>
                                <div className="card_va_inner">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/Sd5OXkWGZQLWl0kc_2023-04-01T120029486012.Icon 4.png"
                                    alt=""
                                  />
                                  <strong>तर्कसंगत पर आधारित</strong>
                                </div>
                              </li>
                            </ul>

                            <div className="see_all">
                              <Link to="/xrbloks">Download</Link>
                            </div>
                          </div>
                          {/* <div className="video_audio_list">
                            <ul>
                              <li>
                                <p>- block based coding</p>
                              </li>
                              <li>
                                <p>- drag and drop based</p>
                              </li>
                              <li>
                                <p>- STEM learning</p>
                              </li>
                              <li>
                                <p>- anaglyph mode</p>
                              </li>
                              <li>
                                <p>- 1500+ 3D Models assets</p>
                              </li>
                              <li>
                                <p>- logic based</p>
                              </li>
                            </ul>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="about_section_new mb-100 video__new_iframe">
                    <div className="common_aboutsub_heading">
                      <h2>प्रफेशनल AR -Vr एप्लीकेशन डेवलपमेंट कोर्स</h2>
                      <p>
                        TechXR प्रदान करता है AR -Vr डेवलपमेंट की ट्रेनिंग वो भी
                        स्मार्टफोन पर आधारित सबसे सस्ते AR Vr डेवलपर किट के साथ
                      </p>
                    </div>

                    <div className="Video_and_audio_container">
                      <div className="common-row">
                        <div className="common-col-7 m-auto common-md-12">
                          <div className="ifarme_for_video">
                            <iframe
                              width="942"
                              height="530"
                              src="https://www.youtube.com/embed/bUdPvNyGgFg"
                              title="Advance AR-VR Development Course Learnings"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                            ></iframe>
                          </div>
                        </div>
                        <div className="common-col-12 common-md-12 mt-50">
                          <div className="super_sub_head text-center mt-40">
                            <h2>हमारे AR VR डेवलपर कोर्स</h2>
                          </div>

                          <div className="video_audio_Course_list">
                            <ul className="common-row">
                              <li className="common-col-3 common-sm-6 ">
                                <div className="course_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/HbI4rxI4JkFTyltG_2023-04-07T093434897836.Thumbnail-intermediate.jpg"
                                    alt=""
                                  />

                                  <div className="coursepoint">
                                    <ul>
                                      <li>
                                        <span>कोर्स की अवधि :</span>{" "}
                                        <strong>8 सप्ताह</strong>
                                      </li>
                                      <li>
                                        <span>
                                          किट शामिल है: <br />{" "}
                                          <span className="include_span">
                                            SenseXR किट
                                          </span>
                                        </span>{" "}
                                        <strong>
                                          <img
                                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/pIgxe7eqw8eKTnYl_2023-04-04T102954450135.Kit.png"
                                            alt=""
                                          />
                                        </strong>
                                      </li>
                                    </ul>

                                    <a
                                      href="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/396WSaxZNKz0bKEc_2023-04-01T123952180798.Intermediate Brochure.pdf"
                                      className="downloadBtn"
                                    >
                                      ब्रोशर डाउनलोड करें
                                    </a>
                                  </div>
                                </div>
                              </li>

                              <li className="common-col-3 common-sm-6">
                                <div className="course_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/4Q9436wGMOCPKj5b_2023-04-07T093542334335.Thumbnail-Advance.jpg"
                                    alt=""
                                  />

                                  <div className="coursepoint">
                                    <ul>
                                      <li>
                                        <span>कोर्स की अवधि :</span>{" "}
                                        <strong>12 सप्ताह</strong>
                                      </li>
                                      <li>
                                        <span>
                                          किट शामिल है: <br />{" "}
                                          <span className="include_span">
                                            SenseXR किट
                                          </span>
                                        </span>{" "}
                                        <strong>
                                          <img
                                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/pIgxe7eqw8eKTnYl_2023-04-04T102954450135.Kit.png"
                                            alt=""
                                          />
                                        </strong>
                                      </li>
                                    </ul>
                                    <a
                                      href="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/CAdQP5s6ZfgCCkHF_2023-04-01T124203026308.Advance.pdf"
                                      className="downloadBtn"
                                    >
                                      ब्रोशर डाउनलोड करें
                                    </a>
                                  </div>
                                </div>
                              </li>

                              <li className="common-col-3 common-sm-6">
                                <div className="course_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/Dt2N3fSH2iF8wYOc_2023-04-03T115310785038.Thumbnail-Expert.jpg"
                                    alt=""
                                  />

                                  <div className="coursepoint">
                                    <ul>
                                      <li>
                                        <span>कोर्स की अवधि :</span>{" "}
                                        <strong>16 सप्ताह</strong>
                                      </li>
                                      <li>
                                        <span>
                                          किट शामिल है: <br />{" "}
                                          <span className="include_span">
                                            SenseXR किट
                                          </span>
                                        </span>{" "}
                                        <strong>
                                          <img
                                            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/pIgxe7eqw8eKTnYl_2023-04-04T102954450135.Kit.png"
                                            alt=""
                                          />
                                        </strong>
                                      </li>
                                    </ul>
                                    <a
                                      href="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/grQMmvnLe4Oi0Rpr_2023-04-07T075855833230.Expert-editable.pdf"
                                      className="downloadBtn"
                                    >
                                      ब्रोशर डाउनलोड करें
                                    </a>
                                  </div>
                                </div>
                              </li>

                              <li className="common-col-3 common-sm-6">
                                <div className="course_base">
                                  <img
                                    src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/4Pr8R03f5rhlBVui_2023-04-03T115517643386.Thumbnail-Professional.jpg"
                                    alt=""
                                  />

                                  <div className="coursepoint">
                                    <ul>
                                      <li>
                                        <span>कोर्स की अवधि :</span>{" "}
                                        <strong>24 सप्ताह</strong>
                                      </li>
                                      <li>
                                        <span>
                                          किट शामिल है: <br />{" "}
                                          <span className="include_span">
                                            SenseXR किट + मेटा क्वेस्ट 2
                                          </span>
                                        </span>{" "}
                                        <strong>
                                          <div className="flex-box_n">
                                            <img src={myimg} alt="" />
                                          </div>
                                        </strong>
                                      </li>
                                    </ul>

                                    <a
                                      href="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/sMXBcY7YML8uZnfg_2023-08-07T112955144811.TechXR Innovations Course.pdf"
                                      className="downloadBtn"
                                    >
                                      ब्रोशर डाउनलोड करें
                                    </a>
                                  </div>
                                </div>
                              </li>
                            </ul>

                            <div className="see_all mt-40">
                              <Link to="/courses">See all</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>

            <div className="journey_main pt-0">
              <div className="common-heading-home mb-50">
                <h2>
                  <span>our</span>
                  Journey
                </h2>
              </div>

              <div className="jourey-stucture">
                <ul>
                  <li>
                    <div className="span-before"></div>
                    <img src={Vectorbg} alt="" className="shape-img" />
                    <div className="list-content">
                      <span>2019-20</span>
                      <p>
                        Work on early prototypes of
                        <strong>6 DoF Controller</strong>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="span-before"></div>
                    <img src={Vectorbg} alt="" className="shape-img" />
                    <div className="list-content">
                      <span>January 2021</span>
                      <p>
                        Incorporation of
                        <strong> TechXR Innovations Private Limited</strong>
                        <br />
                        (5 membered team)
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="span-before"></div>
                    <img src={Vectorbg} alt="" className="shape-img" />
                    <div className="list-content">
                      <span>July 2021</span>
                      <p>
                        <strong>Ranked All India second</strong> in startup
                        competition by Eduprenure <br /> Challenge (Aurobindo
                        Society)
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="span-before"></div>
                    <img src={Vectorbg} alt="" className="shape-img" />
                    <div className="list-content">
                      <span>October 2021</span>
                      <p>
                        Designed and conducted AR-VR Elective course <br />
                        for NIFT Kangra.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="span-before"></div>
                    <img src={Vectorbg} alt="" className="shape-img" />
                    <div className="list-content">
                      <span>December 2021</span>
                      <p>
                        Patent received for <strong>6 DoF Controller</strong>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="span-before"></div>
                    <img src={Vectorbg} alt="" className="shape-img" />
                    <div className="list-content">
                      <span>January 2022</span>
                      <p>
                        <strong className="pl-0">
                          Partnered with iHub @ IIT Roorkee
                        </strong>{" "}
                        for offering Certification Courses in AR-VR
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="span-before"></div>
                    <img src={Vectorbg} alt="" className="shape-img" />
                    <div className="list-content">
                      <span>February 2022</span>
                      <p>
                        Revenue stage -<strong>First paid course</strong>{" "}
                        launched.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="span-before"></div>
                    <img src={Vectorbg} alt="" className="shape-img" />
                    <div className="list-content">
                      <span>July 2022</span>
                      <p>
                        Manufacturing stage - Received first batch of{" "}
                        <strong>patented XR-Controller</strong> after
                        manufacturing
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="vision_main">
              <div className="common-row">
                <div className="vision_left common-sm-12">
                  <div className="vision_img">
                    <img src={Visionbg} alt="" className="visionbg" />
                    <img src={Visionmain} alt="" className="vision-main" />
                  </div>
                </div>
                <div className="vision_right common-sm-12">
                  <div className="common-heading-home mb-50">
                    <h2>
                      <span>our</span>
                      Vision
                    </h2>
                  </div>
                  <div className="content-vision">
                    <p>
                      We believe that Augmented and Virtual reality technology
                      is an extremely potential tool in the fields of{" "}
                      <strong>
                        Education, Training, Architecture, Healthcare, Tourism,
                        Remote Collaboration, Fashion, Security, and Gaming.
                      </strong>{" "}
                      However, due to costly hardware, and lack of affordable
                      courses and content, its adoption is hampered in
                      countries, especially the developing ones like India. But,
                      with our pocket-friendly patented hardware and exclusively
                      designed learning resources, we envision making India a
                      superpower in AR-VR content generation and{" "}
                      <strong>
                        aim to build the world's largest AR-VR Developer's base
                        in India.
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="extra-information">
              <img src={Conbg} alt="" className="con_bg" />
              <p>
                We believe in the spirit of teamwork. A healthy mind breeds
                healthy ideas. We follow the{" "}
                <strong>Work Hard, Play Harder</strong> principle.{" "}
              </p>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default AboutUsSecond;
