import * as React from "react";

export interface IContactUsCompoProps {}

export function ContactUsCompo(props: IContactUsCompoProps) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="contact-main-componet">
        <div className="top-banner-contact">
          <img
            src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/cxmTkxIk6ppPHYv2_2023-01-17T114758308142.pexels-fauxels-3183150.jpg"
            alt=""
          />
          <div className="inner_overlap-contact">
            <h2>Contact Us</h2>
            <p>For any query, Reach out to us at give below mediums.</p>
          </div>
        </div>

        <div className="contact-body py-100">
          <div className="common-container">
            <div className="common-row">
              <div className="common-col-4 common-md-6 common-xs-12 mb-30">
                <div className="inner_contact_body">
                  <div className="contact-icon">
                    <img
                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/K8zrBZM4907T2tn0_2023-01-19T122809034714.Phone.png"
                      alt=""
                    />
                  </div>
                  <h3>Phone</h3>
                  {/* <p className="mb-0">Call / Whatsapp: +91 9109131271 </p> */}
                  <p>
                    For SenseXR Controller <br /> related queries -
                    <span>+91 9109131271</span>
                  </p>

                  {/* <p>
                    For AR-VR Course <br /> related queries -
                    <span>+91 9109131271 </span>
                  </p> */}
                </div>
              </div>
              <div className="common-col-4 common-md-6 common-xs-12 mb-30">
                <div className="inner_contact_body">
                  <div className="contact-icon">
                    <img
                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/kpTPsjpEhm6ZUDX1_2023-01-19T122711651683.address.png"
                      alt=""
                    />
                  </div>
                  <h3>Address</h3>
                  <p>
                    SF-15 Second Floor Aakriti Business Centre, Aakriti Eco
                    City, Bhopal India - 462026
                  </p>
                  <a
                    href="https://goo.gl/maps/8vBMgN5TdYiuH9Kq6"
                    target="blank"
                  >
                    Go To Map
                  </a>
                </div>
              </div>
              <div className="common-col-4 common-md-6 common-xs-12 mb-30">
                <div className="inner_contact_body">
                  <div className="contact-icon">
                    <img
                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/WMXRoqvzq2ufc59k_2023-01-19T122857344234.email.png"
                      alt=""
                    />
                  </div>
                  <h3>Email</h3>
                  <p>sensexr@techxr.co</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
