import * as React from "react";
import ControllerImage from "../../../assets/images/sensexr/trimcontroller.png";
import Bgimage from "../../../assets/images/auth-pages-background/bg_png.png";
import Bgimage2 from "../../../assets/images/auth-pages-background/bg_png2.png";
import { Helmet, HelmetProvider } from "react-helmet-async";

interface CampusAmbassadorProps {}

const CampusAmbassador: React.FunctionComponent<CampusAmbassadorProps> = (
  props
) => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Brand Ambassador Program | TechXR</title>
          <meta name="title" content="Brand Ambassador Program | TechXR" />
          <meta
            name="description"
            content="Be a part of our brand ambassador and know more about the perks and benefits, roles and responsibilities. Visit our website read our program details and much more."
          />
          <meta
            property="image"
            content="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/uD41orAQL8hE2zva_2023-05-30T064448436786.Img web.png"
          />
        </Helmet>
      </HelmetProvider>

      <div className="main-section mx-w-1440">
        <div className="Privacy-main py-100">
          <div className="common-container">
            {/* <Bgimage/> */}
            <img src={Bgimage} alt="" className="bg-overlay" />
            <img src={Bgimage2} alt="" className="bg-overlay second" />
            <div className="Privacy-row">
              <div className="common-heading privacy-heading d-flex-between brandbox-main">
                <h1 className="mb-10">Career @ TechXR innovations</h1>

                {/* <div className="privacy-btn mobile-view">
                  <a
                    href="https://docs.google.com/forms/d/e/1FAIpQLSfWsvbxWis8nT1hLQTy8PwbyTrx2-x1bBk7EFAnBw_w5ABg8g/viewform?vc=0&c=0&w=1&flr=0"
                    target="blank"
                  >
                    Apply Now
                  </a>
                </div> */}
                <div className="img-brand">
                  <img src={ControllerImage} alt="" />
                </div>
              </div>
              <div className="career-main-block">
                <div className="career-inner-block">
                  <div className="career_block_flex">
                    <div className="career_card">
                      <div className="top_career">
                        <strong>Updated: Today</strong>
                        <div className="fresh_hiring">Hiring Experience</div>
                      </div>

                      <h3>Assistant Manager - HR</h3>

                      <ul className="required_list">
                        <li>
                          <span>Location -</span>
                          <strong>Bhopal</strong>
                        </li>
                        <li>
                          <span>Salary:</span>
                          <strong>Up to 5 LPA</strong>
                        </li>
                        <li>
                          <span>Experience -</span>
                          <strong>3 years</strong>
                        </li>
                      </ul>

                      <div className="card_content__career">
                        <h4>Key Responsibilities</h4>
                        <ul className="Responsibilities">
                          <li>
                            Manage accurate and timely payroll processing.
                          </li>
                          <li>Maintain and update HRMS data with integrity.</li>
                          <li>Generate reports and handle payroll queries.</li>
                          <li>
                            Ensure compliance with labor laws and regulations.
                          </li>
                          <li>Experience in PF and ESIC challan filing.</li>
                        </ul>
                      </div>

                      <div className="card_content__career">
                        <h4>Qualifications</h4>
                        <ul className="Responsibilities">
                          <li>
                            Proven expertise in payroll management and HRMS.
                          </li>
                          <li>
                            Knowledge of payroll regulations and compliance.
                          </li>
                          <li>
                            Proficiency in HRMS software and payroll systems.
                          </li>
                          <li>
                            Strong attention to detail and analytical skills.
                          </li>
                        </ul>
                      </div>

                      <div className="career_cta">
                        <a href="https://zcform.in/msK0G" target="_blank">
                          Apply for Job
                        </a>
                      </div>
                    </div>

                    <div className="career_card">
                      <div className="top_career">
                        <strong>Updated : Today</strong>
                        <div className="fresh_hiring">Hiring Experience</div>
                      </div>

                      <h3> Video Editor </h3>

                      <ul className="required_list">
                        <li>
                          <span>Location -</span>
                          <strong>Bhopal</strong>
                        </li>
                        <li>
                          <span>Salary:</span>
                          <strong>Upto 4 LPA </strong>
                        </li>
                        <li>
                          <span>Experience -</span>
                          <strong> 3 years</strong>
                        </li>
                      </ul>

                      <div className="card_content__career">
                        <h4>Responsibilities -</h4>
                        <ul className="Responsibilities">
                          <li>Create cinematic VFX, SFX, and color grading.</li>
                          <li>Edit videos with AI tools like Runway ML.</li>
                          <li>Work on 360 VR videos (preferred).</li>
                          <li>Deliver high-quality, polished outputs.</li>
                          <li>Collaborate with teams to meet project goals.</li>
                        </ul>
                      </div>

                      <div className="card_content__career">
                        <h4>Requirements -</h4>
                        <ul className="Responsibilities">
                          <li>
                            Expertise in Premiere Pro, After Effects, Photoshop.
                          </li>
                          <li>
                            Experience with AI video tools like Runway ML.
                          </li>
                          <li>
                            Creative, proactive, and solution-oriented mindset.
                          </li>
                          <li>
                            Ability to manage deadlines and collaborate
                            effectively.
                          </li>
                        </ul>
                      </div>
                      <div className="career_cta">
                        <a href="https://zcform.in/riNdG" target="_blank">
                          Apply for job
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CampusAmbassador;
