import * as React from "react";
import SkilledProfessionalsImage from "../../../../assets/icons/Skilled Professionals1.png";
import Support24x7Image from "../../../../assets/icons/24x7-0.png";
import CareerGrowthImage from "../../../../assets/icons/Career Growth0.png";

import Nencontrollericon from "../../../../assets/images/newwhitesensexr.png";

interface WhyUsMainCompProps {}

export const WhyUsMainComp: React.FunctionComponent<WhyUsMainCompProps> = (
  props
) => {
  return (
    // <!--Why Us Start-->
    <div className="why-us py-100 bg-dark-blue">
      <div className="common-container">
        <div className="why__us__flex_box">
          <div className="why_us_main_title">
            <div className="common-row">
              <div className="common-col-12 common-sm-12 ">
                <div className="common-heading-home ">
                  <h2>Other Solutions</h2>
                </div>
                <p className="pwhy-text mt-10">
                  Bring learning to life with immersive AR-VR technology, making
                  complex concepts easy and engaging for students. Foster
                  creativity, critical thinking, and future-ready skills.
                </p>
              </div>
            </div>
          </div>

          <div className="new__whyus_block">
            <div className="why_us_card_row d-flex align-items">
              <div className="why___flex">
                <div className="why__img">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/ARVRLABImage.png"
                    alt="SkilledProfessionals"
                    className="why_img"
                  />
                </div>
                <div className="why__content">
                  <div className="heading_set">
                    <h3> AR-VR Lab for Schools</h3>
                  </div>
                  <p className="normal-para">
                    Transform your school's learning experience with our
                    cutting-edge Integrated AR-VR Lab.
                  </p>
                </div>
              </div>

              <div className="why___flex">
                <div className="why__img">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/WeldingSimImage.png"
                    alt="SkilledProfessionals"
                    className="why_img"
                  />
                </div>
                <div className="why__content">
                  <div className="heading_set">
                    <h3>Lab for ITIs : Welding Simulator</h3>
                  </div>
                  <p className="normal-para">
                  Enhance your ITI training programs with our advanced
                  Welding Simulator
                  </p>
                </div>
              </div>

              <div className="why___flex">
                <div className="why__img">
                  <img
                    src="https://s3.ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/DurlabhDarshanDImage.png"
                    alt="SkilledProfessionals"
                    className="why_img"
                  />
                </div>
                <div className="why__content">
                  <div className="heading_set">
                    <h3>Durlabh Darshan</h3>
                  </div>
                  <p className="normal-para">
                  Experience spirituality like never before with immersive, peaceful 360° views of your deity from the sanctum.
                  </p>
                </div>
              </div>

              {/* <div className="why_us_card_col">
                <div className="common-row d-flex-between w-100">
                  <div className="common-col-9">
                    <div className="heading_set">
                      <h3>Lab for ITIs : Welding Simulator</h3>
                    </div>
                    <p className="normal-para">
                      Enhance your ITI training programs with our advanced
                      Welding Simulator
                    </p>
                  </div>
                  <div className="common-col-3">
                    <img
                      src={Nencontrollericon}
                      alt="VRImage1"
                      className="why_img"
                    />
                  </div>
                </div>
              </div> */}
            </div>

            {/* <div className="why_us_card_row d-flex align-items flex-start">
              <div className="why_us_card_col  mb-0">
                <div className="common-row d-flex-between w-100 mb-0">
                  <div className="common-col-9">
                    <div className="heading_set">
                      <h3>Get certified </h3>
                    </div>
                    <p className="normal-para">By iHub IIT Roorkee</p>
                  </div>
                  <div className="common-col-3">
                    <img
                      src="https://s3-ap-south-1.amazonaws.com/co.techxr.system.backend.upload.dev/iB5cKeEwYJU6TNUU_2024-05-03T081609940296.diploma.png"
                      alt="VRImage1"
                      className="why_img"
                    />
                  </div>
                </div>
              </div>

              <div className="why_us_card_col mb-0">
                <div className="common-row d-flex-between w-100">
                  <div className="common-col-9">
                    <div className="heading_set">
                      <h3>Career Growth</h3>
                    </div>
                    <p className="normal-para">
                      Maximize Your Future Potential
                    </p>
                  </div>
                  <div className="common-col-3">
                    <img
                      src={CareerGrowthImage}
                      alt="VRImage1"
                      className="why_img"
                    />
                  </div>
                </div>
              </div>
              <div className="why_before_effect"></div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
